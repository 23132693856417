import { convertDateFormat } from '@/utils/dateUtils'

export default class ChildDetailModel {
  constructor(){
    this.loaded= false;
    this.child = {
      user:{
        name:'',
        nick_name:''
      },
      wallet:{
        amount: 0,
        money_amount: 0,
        point_amount: 0,
      },
      allowance:{
        has_allowance: false
      },
      transaction:{
        is_share: false,
        transaction_list: [],
        // year_month: new Date(),
        // month:'',
        total_income: 0,
        total_spend: 0,
      },
      content:{
        start_day_count: 0,
        solved_count: 0,
        total_reward: 0,
      }
    };

    this.paymentCategory = {}

    this.me = {
      family:{
        is_family_leader: false
      }
    };
  }
  setData(obj){
    const { child, me } = obj;
    this.loaded = true;
    this.child = child;
    // this.child.transaction.month = convertDateFormat(child.transaction.year_month,'M');
    this.paymentCategory = child.payment_category;
    this.me = me;
  }
}