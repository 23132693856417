import ChildDetailModel from '@/views/childDetail/model/ChildDetailModel'
import { GET_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import Vue from 'vue'
import router from '@/router';

export default class ChildDetailViewModel {
  constructor() {
    this.model = new ChildDetailModel();
    this.childUid = '';
  }
  init(childUid){
    if(childUid){
      this.childUid = childUid;
      this.getChildDetail();
    }
  }
  onClickAllowance(){
    // 용돈 계약 유무
    if(this.model.child.allowance.has_allowance){
      // 계약 O => 용돈계약서 보기
      this.pageMove('allowanceContract')
    }else{
      // 계약 X => 용돈계약하기
      this.pageMove('allowanceContractCreate')
    }
  }
  onClickCardAdmin(){
    this.pageMove('cWalletSet')
  }

  onClickDetail(transaction_uid){
    let newQuery = JSON.parse(JSON.stringify(router.app._route.query));
    newQuery.userType = 'PARENTS_CHILD';
    newQuery.closeRouteName = 'ChildDetail';
    router.push({name:'TransDetail', params:{ id : transaction_uid }, query: newQuery});
  }

  sendInterface(name,data,dataKeyName){
    const isData = Boolean(data && dataKeyName);
    //안드로이드
    if ( Vue.prototype.$varUA === 'ANDROID') {
      window.FirFinInterface[name](isData ? data : '');
    }
    //IOS
    else if ( Vue.prototype.$varUA === 'IOS' ) {
      let msg;
      if(isData){
        msg = {};
        msg.name = name;
        msg[dataKeyName] = data;
      }else{
        msg = name;
      }
      window.webkit.messageHandlers.FirFinInterface.postMessage(msg);
    }
    return;
  }
  pageMove(pageName){
    this.sendInterface('pageMove',pageName,'pageName');
  }

  getChildDetail(){
    const path = `${apiPath.CHILD_DETAIL.format(this.childUid)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;

      this.model.setData(resultData)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}