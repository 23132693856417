<template>
  <PageWithLayout
    bgColor="#F3F1F1"
    :topPadding="16"
    :bottomPadding="64"
    :leftRightPadding="20"
    :isBackBtn="true"
    :isBackWebEnd="true"
    :isTitle="true"
    :title="$poData.child_details_title.format(viewModel.model.child.user.name)">
    <WalletHead
      :title="$poData.child_details_wallet_title_2.format(viewModel.model.child.user.nick_name)"
      :amount="viewModel.model.child.wallet.amount"
      :money_amount="viewModel.model.child.wallet.money_amount"
      :point_amount="viewModel.model.child.wallet.point_amount">
      <Button
        btnStyle="primary"
        :text="$poData.child_details_wallet_send_btn"
        @onClickBtn="pageMove('sendPocketMoney')" />
    </WalletHead>
    <!-- 용돈 계약 -->
    <div class="box_section box_section_allowance">
      <ListItem
        :padding="16"
        :thumbNail="{
          size: 48,
          url : '/assets/images/child_detail/icon_detail_allowance48.png'
        }"
        :title="$poData.child_details_heading_1"
        :leftSubText="viewModel.model.loaded ? viewModel.model.child.allowance.has_allowance ? $poData.child_details_view_contract : $poData.child_details_no_contract.format(viewModel.model.child.user.nick_name) : ''"
        :leftSubTextColor="viewModel.model.child.allowance.has_allowance ? 'blue' : 'red'"
        @onClickItem="viewModel.onClickAllowance()">
        <template v-slot:leftSubSlot>
          <span :class="['icon_allowance', `icon_allowance_${viewModel.model.child.allowance.has_allowance}`]"></span>
        </template>
        <template v-slot:rightSlot>
          <IconSvg
            iconName="arrow_right"
            :size="24"
            iconColor="#A6A4A4"/>
        </template>
      </ListItem>
    </div>
    <!-- 통계 -->
    <CategoryStatistic
      :childUserUid="viewModel.childUid"
      :paymentCategory="viewModel.model.paymentCategory"
      :isShare="viewModel.model.child.transaction.is_share"
      routeName="ParentsTransListChild"
      :routeQuery="{childUid : viewModel.childUid}"/>
    <!-- 최근 내역 -->
    <div class="box_section box_section_transaction">
      <ButtonRouter
        v-if="viewModel.model.child.transaction.is_share && viewModel.model.child.transaction.transaction_list.length > 0"
        :text="$poData.child_details_heading_2"
        :isArrowRight="true"
        routeName="ParentsTransListChild"
        :routeQuery="{childUid : viewModel.childUid}">
        <template v-if="viewModel.model.wallet_transaction_count > 0" v-slot:btn_right>
          <span class="txt_router">전체보기</span>
        </template>
      </ButtonRouter>
      <strong v-else class="tit_section" v-html="$poData.child_details_heading_2"></strong>
      <template v-if="viewModel.model.loaded">
        <template v-if="viewModel.model.child.transaction.is_share">
          <template v-if="viewModel.model.child.transaction.transaction_list.length > 0">
            <ListItem
              v-for="(itemData, index) in viewModel.model.child.transaction.transaction_list"
              :key="`transItem${index}`"
              :padding="16"
              :thumbNail="{
                size: 40,
                url : itemData.profile_image_url
              }"
              :title="itemData.title"
              :leftSubText="itemData.sub_title"
              :leftBadge="{
                value : itemData.label_display_name,
                type : 'sub1'
              }"
              :rightSubText="itemData.amount | convertNumberToComma(false,'0','원')"
              :isRightSubTextColor="itemData.amount >= 0 ? 'red' : ''"
              @onClickItem="viewModel.onClickDetail(itemData.transaction_uid)">
            </ListItem>
          </template>
          <template v-else>
            <div class="box_empty">
              <p class="desc_empty" v-html="$poData.child_details_trans_list_empty"></p>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="box_disabled">
            <strong class="tit_disabled"><span class="icon_disabled"></span>{{ $poData.child_details_trans_list_hidden_1 }}</strong>
            <p class="desc_disabled">{{ $poData.child_details_trans_list_hidden_2_1.format(viewModel.model.child.user.nick_name) }}<span class="tc_primary">{{ $poData.child_details_trans_list_hidden_2_2 }}</span>{{ $poData.child_details_trans_list_hidden_2_3 }}</p>
          </div>
        </template>
      </template>
    </div>
    <!-- O월 수입소비 -->
    <!-- <div class="box_section box_section_content_total">
      <strong class="tit_section">{{ $poData.child_details_heading_3.format(viewModel.model.child.transaction.month) }}</strong>
      <ListItem
        :padding="8"
        :thumbNail="{
          size: 40,
          url : '/assets/images/child_detail/icon_detail_total_income40.png'
        }"
        :title="$poData.child_details_total_income"
        :rightSubText="viewModel.model.child.transaction.total_income | convertNumberToComma(false,'0원','원')"
        :isRightSubTextColor="viewModel.model.child.transaction.total_income === 0 ? 'gray':''">
      </ListItem>
      <ListItem
        :padding="8"
        :thumbNail="{
          size: 40,
          url : '/assets/images/child_detail/icon_detail_total_spend40.png'
        }"
        :title="$poData.child_details_total_spend"
        :rightSubText="viewModel.model.child.transaction.total_spend | convertNumberToComma(false,'0원','원')"
        :isRightSubTextColor="viewModel.model.child.transaction.total_spend === 0 ? 'gray':''">
      </ListItem>
    </div> -->
    <!-- 퍼핀월드 학습현황 -->
    <div class="box_section box_section_content">
      <strong class="tit_section" v-html="$poData.child_details_heading_4"></strong>
      <ListItem
        :padding="8"
        :thumbNail="{
          size: 40,
          url : '/assets/images/child_detail/icon_detail_started_date40.png'
        }"
        :title="$poData.child_details_world_summary_1"
        :rightSubText="(viewModel.model.child.content.start_day_count > 0 ? '+' : '') + viewModel.model.child.content.start_day_count | convertNumberToComma(false,'0일','일')"
        :isRightSubTextColor="viewModel.model.child.content.start_day_count > 0 ? 'primary':'gray'">
      </ListItem>
      <ListItem
        :padding="8"
        :thumbNail="{
          size: 40,
          url : '/assets/images/child_detail/icon_detail_completed_quiz40.png'
        }"
        :title="$poData.child_details_world_summary_2"
        :rightSubText="viewModel.model.child.content.solved_count | convertNumberToComma(false,'0개','개')"
        :isRightSubTextColor="viewModel.model.child.content.solved_count > 0 ? 'primary':'gray'">
      </ListItem>
      <ListItem
        :padding="8"
        :thumbNail="{
          size: 40,
          url : '/assets/images/child_detail/icon_detail_total_reward40.png'
        }"
        :title="$poData.child_details_world_summary_3"
        :rightSubText="viewModel.model.child.content.total_reward | convertNumberToComma(false,'0원','원')"
        :isRightSubTextColor="viewModel.model.child.content.total_reward > 0 ? 'primary':'gray'">
      </ListItem>
    </div>
    <!-- 자녀 카드 관리 -->
    <div class="box_section box_section_card">
      <ListItem
        :padding="10"
        :thumbNail="{
          size: 48,
          url : '/assets/images/child_detail/icon_detail_firfincard48.png'
        }"
        :title="$poData.child_details_heading_5"
        @onClickItem="viewModel.onClickCardAdmin()">
        <template v-slot:rightSlot>
          <IconSvg
            iconName="arrow_right"
            :size="24"
            iconColor="#A6A4A4"/>
        </template>
      </ListItem>
    </div>
    <!-- 팝업 -->
    <!-- <template v-slot:popup>
      <Layer
        :title="$poData.money_helpersheet_title"
        v-if="viewModel.isMoneyInfoPopup"
        @onClickClose="viewModel.onClickMoneyInfoClose()">
        <p class="desc_drop" v-html="$poData.money_helpersheet_desc"></p>
      </Layer>
      <Layer
        :title="$poData.point_helpersheet_title"
        v-if="viewModel.isPointInfoPopup"
        @onClickClose="viewModel.onClickPointInfoClose()">
        <p class="desc_drop" v-html="$poData.point_helpersheet_desc"></p>
      </Layer>
    </template> -->
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import WalletHead from '@/components/common/walletHead/WalletHead';
import Button from '@/components/common/button/Button';
import ButtonRouter from '@/components/common/button/ButtonRouter';
import ListItem from '@/components/common/list/ListItem';
import CategoryStatistic from '@/components/common/categoryStatistic/CategoryStatistic';
import IconSvg from '@/components/common/icon/IconSvg';
import Layer from '@/components/layout/popup/Layer';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import ChildDetailViewModel from '@/views/childDetail/viewModel/ChildDetailViewModel'

export default {
  name: 'ChildDetail',
  mixins: [ InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    WalletHead,
    Button,
    ButtonRouter,
    ListItem,
    CategoryStatistic,
    IconSvg,
    Layer,
  },
  beforeMount(){
    const childUid = this.$route.query.childUid;
    this.viewModel.init(childUid);

    // 상세다녀와서 closeRouteName 제거
    let currentQuery = JSON.parse(JSON.stringify(this.$route.query));
    currentQuery.closeRouteName = '';
    let newQuery = Object.fromEntries(Object.entries(Object.assign(currentQuery)).filter(([_, v]) => v != '' && v != undefined  && v != 'undefined' ));
    this.$router.replace({'query': newQuery}).catch(()=>{});
  },
  data(){
    return{
      viewModel: new ChildDetailViewModel(),
    }
  },
  methods:{
    onClickBackKey(){
      if(this.viewModel.isMoneyInfoPopup){
        this.viewModel.onClickMoneyInfoClose();
      }
      else if(this.viewModel.isPointInfoPopup){
        this.viewModel.onClickPointInfoClose();
      }
      else{
        this.webEnd();
      }
    }
  }
}
</script>

<style scoped>
.box_section{overflow:hidden;padding:20px 0;border-radius:8px;background-color:#fff}
.box_section + .category_statistic,
.category_statistic + .box_section,
.box_section + .box_section{margin-top:16px}
.box_section .tit_section,
.box_section_allowance .item_list >>> .tit_item,
.box_section_transaction .item_router >>> .tit_router,
.box_section_card .item_list >>> .tit_item{font-weight:700;font-size:18px;line-height:28px;color:#111}
.box_section .tit_section{display:block;padding:0 20px}

.item_list >>> .area_right .desc_item{font-weight:700;font-size:18px;line-height:28px}
.item_list >>> .icon_arrow_right{margin-right:-4px}

/* allowance */
.box_section_allowance{padding:0}
.box_section_allowance .item_list >>> .desc_item{margin-top:0;font-size:14px;line-height:22px}
.icon_allowance_false{background-image:url(/assets/images/child_detail/icon_detail_allowance_false.png)}
.icon_allowance_true{background-image:url(/assets/images/child_detail/icon_detail_allowance_true.png)}
.icon_allowance{display:inline-block;width:20px;height:20px;margin:1px 4px 1px 0;vertical-align:top;background-size:contain;background-repeat:no-repeat}

/* transaction */
.box_section_transaction .item_router >>> .tit_router{padding:0 20px}
.box_section_transaction .item_router >>> .icon_arrow_right{margin:0 16px 0 0}
.box_section_transaction .item_router >>> .icon_arrow_right g{fill:#A6A4A4}
.box_section_transaction .box_empty{margin:16px 20px 0;padding:16px;border:1px dashed #A6A4A4;border-radius:4px}
.box_section_transaction .box_empty .desc_empty{font-weight:500;font-size:14px;line-height:22px;color:#999}
.box_section_transaction .box_disabled{margin:16px 20px 0;padding:16px;border-radius:4px;background-color:#F7F6FA;text-align:center}
.box_section_transaction .tit_disabled{display:block;font-weight:700;font-size:16px;line-height:24px;color:#111}
.box_section_transaction .tit_disabled .icon_disabled{display:inline-block;width:24px;height:24px;margin-right:8px;background:url(/assets/images/child_detail/icon_detail_disabled.png) no-repeat;background-size:contain;vertical-align:top}
.box_section_transaction .desc_disabled{margin-top:8px;font-weight:500;font-size:14px;line-height:22px;color:#999}

/* content_total & content */
.box_section_content_total .tit_section + .item_list,
.box_section_content .tit_section + .item_list{margin-top:16px}
.box_section_content_total .item_list + .item_list,
.box_section_content .item_list + .item_list{margin-top:20px}

/* card */
.box_section_card .item_list >>> .thumb_item{border-radius:0}

.box_drap_wallet_admin >>> .head_drop{padding-bottom:24px}
.box_drap_wallet_admin >>> .btn_secondary_tonal + .btn_secondary_tonal{margin-top:16px}
</style>